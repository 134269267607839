import React from 'react'
import '../footer/footer.css'

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="footer_content">
                    <div className="footer_item">
                        <h3>Реквизиты</h3>
                        <div className="rexisites_title">ООО УК Вэртекс</div>
                        <div className="reg_num"><span>ИНН:</span>5260459878</div>
                        <div className="reg_num"><span>ОГРН:</span>1185275067196</div>

                        <div className="footer_block founder">
                            <div className="footer_block_title">Директор</div>
                            <div className="footer_block_item">Князькина Елена Владимировна</div>
                        </div>

                        <div className="footer_block legal_adress">
                            <div className="footer_block_title">Юридический адрес</div>
                            <div className="footer_block_item">Нижегородская область, г. Нижний Новгород, ул. Ванеева,
                                д.127,
                                помещ. 427
                            </div>
                        </div>

                        <div className="footer_block post_adress">
                            <div className="footer_block_title">Почтовый адрес</div>
                            <div className="footer_block_item">Нижегородская область, г. Нижний Новгород, ул. Ванеева,
                                д.127,
                                помещ. 427
                            </div>
                        </div>
                    </div>

                    <div className="footer_item">
                        <h3>Офис</h3>
                        <div className="footer_block office_adress">
                            <div className="footer_block_title">Адрес</div>
                            <div className="footer_block_item">Нижегородская область, г. Нижний Новгород, ул. Ванеева,
                                д.127,
                                помещ. 427
                            </div>
                        </div>

                        <div className="footer_block working_mode">
                            <div className="footer_block_title">Режим работы</div>
                            <div className="footer_block_item">пн-чт: с 9-00 до 18-00</div>
                            <div className="footer_block_item">пт: с 9-00 до 17-00</div>
                            <div className="footer_block_item">обед: с 13-00 до 13-48</div>
                            <div className="footer_block_item">Часы приема: по предварительному согласованию</div>
                        </div>

                        <div className="footer_block phone">
                            <div className="footer_block_title">Телефоны</div>
                            <div className="footer_block_item">+7(996)567-15-50</div>
                        </div>

                        <div className="footer_block mail">
                            <div className="footer_block_title">Электронная почта</div>
                            <div className="footer_block_item">info@uk-vertex.ru</div>
                        </div>
                    </div>


                    <div className="footer_item">
                        <h3>Диспетчерская</h3>
                        <div className="footer_block phone">
                            <div className="footer_block_title">Телефоны</div>
                            <div className="footer_block_item">+7(996)567-15-50</div>
                        </div>

                        <div className="footer_block working_mode">
                            <div className="footer_block_title">Режим работы</div>
                            <div className="footer_block_item">с 9-00 до 18-00</div>
                        </div>

                        <div className="footer_block control_adress">
                            <div className="footer_block_title">Адрес</div>
                            <div className="footer_block_item">Нижегородская область, г. Нижний Новгород, ул. Ванеева,
                                д.127,
                                помещ. 427
                            </div>
                        </div>
                        <div className="footer_block control_adress">
                            <div className="footer_block_title">Аварийно-диспетчерская служба</div>
                            <div className="footer_block_item">8(831)215-63-93</div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
