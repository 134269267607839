import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import FormModal from '../FormModal';
import './indexPage.css';
import { useState, useEffect } from 'react';
//import { downloadFile } from '../../../../actions/file';
import { ImCross } from 'react-icons/im';
import Alert from '../../../modal/Alert';
import { deleteFIle } from '../../../../actions/file';
import { toggleFileDel } from '../../../../store/fileSlice'
import logo from '../../../images/logo.jpg'

export default function IndexPage() {
    const isAdmin = useSelector(state => state.checkUserData.roles);
    const uploadAction = useSelector(state => state.fileAdd.add);
    const deleteAction = useSelector(state => state.fileAdd.del);
    const [files, setFiles] = useState(null);
    const dispatch = useDispatch();

    /*     const getFiles = async () =>{
            const res = await fetch('api/documents')
            const docs = await res.json();
            setFiles(docs);
        }; */

    /*     const deleteDocument = async (id, token)=>{
            await deleteFIle(id, token);
            dispatch(toggleFileDel());
        } */

    /*     useEffect(()=>{
            getFiles();
        }, [uploadAction, deleteAction, isAdmin]) */

    const pageFiles = files?.map(({ id, filelink, fileTitle }) => {
        return (
            <div key={id} className="page_files">
                <div className="files_title">
                    <div className="files_title_text">{fileTitle}</div>
                </div>
                <div className="files_available">
                    <button className="btn_download"
                    /* onClick={()=>downloadFile(id, filelink)} */
                    >Скачать </button>
                    <span style={{ fontSize: '0.6rem' }}>{filelink}</span>
                </div>
                {isAdmin.includes('admin') ?
                    <Alert modalButton={<ImCross className='delete_file' />}
                        modalTitle={'Удаление документа'}
                        modalMessage={`Фаил "${fileTitle}" будет безвозвратно удален! Вы уверены?`}
                        /* action={deleteDocument} */
                        id={id}
                    /> :
                    null}
            </div>
        )
    })

    return (
        <div>
            <div className="page_titile">
                <img src={logo} alt="logo" />
            </div>
            <div className="page_subtitle">
                <h1>Уважаемые собственники! Рады приветствовать Вас на нашем сайте!</h1>
                <p>ООО "УК ВЭРТЕКС" - управляющая организация в сфере управления МКД.</p>
                <p>Мы предлагаем свои услуги по управлению многоквартирными  домами.</p>
                <p>Компания обладает необходимыми трудовыми ресурсами (в штате компании работают слесари-сантехники, электрики, кровельщики, бухгалтера, юристы, инженеры, сметчики) и оборудованием для выполнения работ и оказания услуг по содержанию и ремонту общего имущества МКД.</p>
                <p>Своей основной задачей мы видим создание комфортных условий проживания жителей многоквартирного дома, обеспечение надлежащего содержания дома, решение вопросов для удобного и рационального пользования общим имуществом, бесперебойное предоставление коммунальных услуг надлежащего качества. всем собственниками помещений в доме.</p>
                <p>Наша Управляющая Организация тесно работает с собственниками жилья. Рассматривает предложения, заявления и жалобы от собственников, ведет их учет, принимает меры, необходимые для устранения указанных в них недостатков в установленные сроки, осуществляет контроль за устранением недостатков. Разрабатывает и исполняет текущие и перспективные планы работ и услуг по содержанию и текущему ремонту общего имущества многоквартирного дома.</p>
                <p>Мы работаем на принципах открытости, прозрачности информации, обеспечения конкурентоспособности, профессионализма сотрудников.</p>
                <p>ООО " УК ВЭРТЕКС" по-настоящему дорожит своей репутацией!</p>
                <p>При выборе Нашей управляющей компании жители получат:</p>
                <ul>
                    <li>чистую воду в доме(замена стояков ГВС)</li>
                    <li>свой чат, с возможностью получения быстрых ответов на все интересующие вопросы</li>
                    <li>дистанционное обслуживание до почтового ящика</li>
                    <li>поддерживание чистоты и порядок на должном уровне</li>
                    <li>быстрые отклики на заявки собственников, не связанных с ИОИ(ремонт замка, покраска ограждений и др.), оперативное решение возникающих вопросов</li>
                    <li>конструктивное урегулирование спорных вопросов между собственниками и компанией.</li>
                    <li>готовы ответить на все интересующие вопросы.</li>
                </ul>
            </div>
{/*             <div className="documents">
                {pageFiles ? pageFiles : <div style={{ textAlign: 'center' }} >Нет документов </div>}
                {isAdmin.includes('admin') ?
                    <div className='btn-modal'><FormModal /></div> :
                    null}
            </div> */}
        </div>
    )
}
