import React from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { AiOutlinePrinter, AiOutlineDownload } from 'react-icons/ai';
import print from 'print-js';
import './aboutPage.css'

export default function AboutPage() {
  return (
    <div className="about_wrapper">
      <div className="page_titile">
        <h1>О компании</h1>
      </div>
      <div className="form_about">
        <p>Форма 1.1 — Общая информация об управляющей организации, товариществе, кооперативе</p>
      </div>
{/*       <div className="btn_group">
        <div className='btn_report'>Отчетный период: 2022 год <IoMdArrowDropdown />
          <ul className="dropdown_pull">
            <li>Отчетный период: 2023 год</li>
            <li>Отчетный период: 2024 год</li>
          </ul>
        </div>
        <button className='btn_report' type="button" onClick={() => print('/')}><AiOutlinePrinter /> Распечатать форму</button>
        <button className='btn_report'><a href="/"><AiOutlineDownload /> Скачать форму</a></button>
      </div> */}
      <FormTableRender />
    </div>

  )
}
function FormTableRender() {
  // Данные, которые потом будут загружаться на сервер и приходить с него
  const totalInformation = [
    { id: 1, number: '2.', text: 'Организационно-правовая форма', value: `Общества с ограниченной ответственностью (1 23 00)` },
    { id: 2, number: '-', text: 'Полное наименование юридического лица', value: `Общество с ограниченной ответственностью "УК Вэртекс"` },
    { id: 3, number: '3.', text: 'Сокращенное наименование', value: `ООО "УК Вэртекс"` },
    { id: 4, number: '4.', text: 'ФИО руководителя', value: 'Князькина Елена Владимировна' },
    { id: 5, number: '5.', text: 'ОГРН/ОГРНИП', value: '1185275067196' },
    { id: 6, number: '6.', text: 'ИНН', value: '5260459878' },
    { id: 7, number: '7.', text: `Место государственной регистрации юридического лица (место нахождения юридического лица)`, value: `603106, Нижегородская область,Г.О. ГОРОД НИЖНИЙ НОВГОРОД,Г НИЖНИЙ НОВГОРОД,УЛ ВАНЕЕВА, Д. 127, ПОМЕЩ. 427` },
    { id: 8, number: '8.', text: 'Почтовый адрес', value: '603106, Нижегородская область,Г.О. ГОРОД НИЖНИЙ НОВГОРОД,Г НИЖНИЙ НОВГОРОД,УЛ ВАНЕЕВА, Д. 127, ПОМЕЩ. 427' },
    { id: 9, number: '9.', text: 'Адрес электронной почты', value: 'info@uk-vertex.ru' },
    { id: 10, number: '10.', text: 'Официальный сайт', value: 'uk-vertex.ru' },
    { id: 11, number: '11.', text: `Место нахождение органов управления (фактический адрес)`, value: 'не заполнено' },
    { id: 12, number: '12.', text: 'Контактные телефоны, факс', value: '+7(996)567-15-50, не заполнено' },
    { id: 13, number: '13.', text: 'Режим работы, в том числе часы личного приёма граждан', value: `с 9-00 до 18-00\nЧасы приема: с 16-00 до 18-00` },
    { id: 14, number: '14.', text: 'Сведения о работе диспетчерской службы:', value: 'не заполнено' },
    { id: 15, number: '15.', text: '- адрес', value: 'не заполнено' },
    { id: 16, number: '16.', text: '- контактные телефоны', value: 'не заполнено' },
    { id: 17, number: '17.', text: '- режим работы', value: 'круглосуточно' },
    { id: 18, number: '18.', text: 'Доля участия субъекта Российской Федерации в уставном капитале организации', value: 'не заполнено' },
    { id: 19, number: '19.', text: 'Доля участия муниципального образования в уставном капитале организации', value: 'не заполнено' },
    { id: 20, number: '20.', text: 'Количество домов, находящихся в управлении', value: 'не заполнено' },
    { id: 21, number: '21.', text: 'Площадь домов, находящихся в управлении', value: 'не заполнено' },
    { id: 22, number: '22.', text: 'Штатная численность сотрудников, всего', value: 'не заполнено' },
    { id: 23, number: '-', text: 'Штатная численность административного персонала', value: 'не заполнено' },
    { id: 24, number: '-', text: 'Штатная численность инженеров', value: 'не заполнено' },
    { id: 25, number: '-', text: 'Штатная численность рабочих', value: 'не заполнено' },
    { id: 26, number: '23.', text: 'Устав товарищества собственников жилья или кооператива', value: 'не заполнено' },
    { id: 27, number: '24.', text: 'Сведения о членстве УО в СРО', value: 'не заполнено' }
  ]
  const licence = [
    { id: 28, number: 36, text: 'Номер лицензии', value: '617' },
    { id: 29, number: 37, text: 'Дата получения лицензии', value: '28.02.2019' },
    { id: 30, number: 38, text: 'Орган, выдавший лицензию', value: 'Государственная жилищная инспекция Нижегородской области' },
    { id: 31, number: 39, text: 'Документ лицензии', value: <a target='_blank' href='/downloads/licence.pdf'>Просмотр</a> }
  ];

  const tableRow = totalInformation.map(({ id, number, text, value }) => {
    return (
      <div key={id} className="table_row">
        <div className="tab_number">{number}</div>
        <div className="table_text">{text}</div>
        <div className="table_value">{value}</div>
      </div>
    )
  })
  
  const licenceRow = licence.map(({ id, number, text, value }) => {
    return (
      <div key={id} className="table_row">
        <div className="tab_number">{number}</div>
        <div className="table_text">{text}</div>
        <div className="table_value">{value}</div>
      </div>
    )
  })

  return (
    <div className="table_form">
      <div className="title">ФОРМА 1.1 — ОБЩАЯ ИНФОРМАЦИЯ ОБ УО, ТСЖ, ЖК И ИНОМ СПК</div>
      <div className="table_content">
        <div className="table_row row_header">
          <div className="tab_number">№</div>
          <div className="table_text">Наименование параметра</div>
          <div className="table_value">Значение</div>
        </div>
        <div className="table_row">
          <div className="tab_number">1</div>
          <div className="table_text">Дата заполнения/внесения изменений</div>
          <div className="table_value">не заполнено</div>
        </div>
      </div>
      <div className="subtitle">ОБЩАЯ ИНФОРМАЦИЯ ОБ ОРГАНИЗАЦИИ</div>
      {tableRow}
      <div className="subtitle">Лицензии</div>
      {licenceRow}
    </div>
  )
}